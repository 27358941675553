import { gql } from '@apollo/client';
// Fragments
import {
  MerchProductExtendedFields,
  MerchProductBaseFields,
} from './fragments';
import { ThankYouVideoFields } from 'api/thankYouVideo/fragments';

export const GET_MERCH_PRODUCT = gql`
  query GetMerchProduct($input: GetMerchProductInput!) {
    getMerchProduct(input: $input) {
      ...MerchProductExtendedFields
      autoSocialMediaPackStatus
      autoSocialPackImages {
        id
        status
        url
      }
    }
  }
  ${MerchProductExtendedFields}
`;

export const GET_STORE_MERCH_PRODUCT = gql`
  query GetStoreMerchProduct($input: GetMerchProductInput!) {
    getStoreMerchProduct(input: $input) {
      id
      title
      description
      slug
      type
      hashtags {
        id
        name
      }
      merchProductVariants {
        id
        age
        gender
        size
        color
        colorCode
        colorCode2
        colorGroupId
        price
        mainMockupUrl
        mockupImageURL
        mockupImages {
          id
          merchProductId
          mockupImageURL
          placement
        }
        customImages {
          id
          customMerchImageURL
          imageFileKey
          isMainImage
        }
      }
      mainImageUrl
      minPrice
      store {
        id
        slug
        storeDetails {
          storeName
        }
      }
    }
  }
`;

export const GET_MERCH_PRODUCTS = gql`
  query GetMerchProducts(
    $input: GetMerchProductsInput
    $withFilters: Boolean = false
  ) {
    getMerchProducts(input: $input) {
      entities {
        ...MerchProductBaseFields
      }
      total
      offset
      limit
      filters @include(if: $withFilters) {
        price
        genderV2 {
          name
        }
        type {
          name
        }
        size {
          name
        }
        color {
          color
          colorCode
          colorCode2
        }
        sports {
          id
          name
        }
      }
    }
  }
  ${MerchProductBaseFields}
`;

/**
 * Get merch products with minimal info to display in related products list
 */
export const GET_MERCH_MINIMAL_INFO = gql`
  query GetMerchMinimalInfo($input: GetMerchProductsInput) {
    getMerchProducts(input: $input) {
      entities {
        id
        title
        mainImageUrl
        slug
        status
        minPrice
        store {
          id
          firstName
          lastName
          slug
          storeDetails {
            storeName
          }
        }
      }
    }
  }
`;

export const GET_MERCH_BY_IDS = gql`
  query GetMerchByIds($input: GetMerchProductsByIds!) {
    getMerchProductsByIds(input: $input) {
      id
      slug
      autoSocialMediaPackStatus
      autoSocialPackImages {
        id
        status
        url
      }
    }
  }
`;

export const GET_MY_MERCH_PRODUCTS = gql`
  query GetMyMerchProducts {
    getMyMerchProducts {
      ...MerchProductExtendedFields
      autoSocialMediaPackStatus
      autoSocialPackImages {
        id
        status
        url
      }
    }
  }
  ${MerchProductExtendedFields}
`;

export const GET_PR_PRODUCT_BY_TYPE_V2 = gql`
  query GetPRProductByTypeV2($type: GetPrProductsInput!) {
    getPRProductByTypeV2(input: $type) {
      age
      gender
      data {
        features
        printfiles {
          printfiles {
            printfile_id
            width
            height
            dpi
            fill_mode
            can_rotate
          }
        }
        product {
          variants {
            id
            price
            image
            color
            color_code
            color_code2
            in_stock
          }
          product {
            id
            options
            files {
              additional_price
              id
              title
              type
            }
          }
        }
        style
        templates {
          conflicting_placements {
            conflicts
            placement
          }
          version
          min_dpi
          templates {
            template_id
            background_color
            background_url
            image_url
            is_template_on_front
            orientation
            print_area_height
            print_area_left
            print_area_top
            print_area_width
            printfile_id
            template_height
            template_width
          }
          variant_mapping {
            variant_id
            templates {
              placement
              template_id
            }
          }
        }
      }
    }
  }
`;

export const GET_MY_MERCH_PRODUCT_V2 = gql`
  query GetMyMerchProductV2($input: GetMerchProductInput!) {
    getMerchProduct(input: $input) {
      id
      requestedProfit
      title
      type
      hashtags {
        id
        name
      }
      merchProductVariants {
        id
        age
        gender
        stitches
        threads
        files {
          id
          merchProductPrintfilePlacements {
            id
            angle
            printImageId
            left
            top
            rotatedLeft
            rotatedTop
            scaleX
            scaleY
          }
          placementType
          imageFileKey
        }
        color
        colorCode
        colorCode2
        colorGroupId
        printfulVariantId
        customImages {
          imageFileKey
          isMainImage
        }
        stitches
        threads
      }
    }
  }
`;

export const GET_MY_PRINT_IMAGES = gql`
  query GetMyPrintImages {
    getMyPrintImages {
      id
      imageURL
    }
  }
`;

export const GET_MERCH_CART_ITEM_LIST = gql`
  query GetMerchCartItemsList {
    getMerchCartItemsList {
      id
      variantId
      amount
      memorabiliaId
      memorabilia {
        id
        slug
        price
        title
        numberOfUnits
        memorabiliaProductType
        store {
          id
          storeDetails {
            id
            storeName
          }
        }
        images {
          id
          isMainImage
          memorabiliaId
          key
          url
        }
      }
      merchProductVariant {
        id
        customImages {
          id
          isMainImage
          imageFileKey
          customMerchImageURL
        }
        merchProduct {
          id
          type
          title
          mainImageUrl
          productImageURL
          merchProductVariants {
            id
            size
            color
            merchProductId
            colorCode
            age
            gender
            stitches
            size
            threads
            colorGroupId
            stitches
            threads
          }
          store {
            id
            storeDetails {
              storeName
            }
          }
        }
        size
        color
        age
        gender
        colorCode
        colorCode2
        colorGroupId
        price
        stitches
        threads
        merchProductId
        mainMockupUrl
        mockupImageURL
        mockupImageFileKey
        printfulVariantId
        mockupImages {
          id
          merchProductId
          mockupImageURL
          placement
        }
      }
    }
  }
`;

export const GET_PRINT_IMAGE_BY_ID = gql`
  query GetPrintImageById($input: PrintImageInput!) {
    getPrintImageById(input: $input) {
      id
      imageKey
      deletedAt
      imageURL
    }
  }
`;

export const GET_MY_MERCH_SALES = gql`
  query GetMerchSales($input: MySalesInput) {
    getMerchSales(input: $input) {
      total
      offset
      limit
      entities {
        id
        requestedProfit
        discountAmount
        createdAt
        total
        printfulOrderId
        country
        state
        city
        addressLine1
        addressLine2
        zipCode
        customer {
          id
          lastName
          firstName
        }
        thankYouVideo {
          ...ThankYouVideoFields
        }
        merchOrderItems {
          id
          merchProductVariant {
            id
            size
            color
            mockupImageURL
          }
          merchProduct {
            id
            title
            productImageURL
          }
          amount
        }
      }
    }
  }
  ${ThankYouVideoFields}
`;

export const FIND_ORDER = gql`
  query FindOrder($input: FindOrderInput!) {
    findOrder(input: $input) {
      billingAddress {
        addressLine1
        addressLine2
        city
        company
        country
        countryCode
        email
        firstName
        lastName
        phone
        state
        stateCode
        zipCode
      }
      shippingAddress {
        addressLine1
        addressLine2
        city
        company
        country
        countryCode
        email
        firstName
        lastName
        phone
        state
        stateCode
        zipCode
      }
      promocode {
        id
        amount
        name
      }
      order {
        id
        createdAt
        discountAmount
        paidAt
        promoRevenue
        shipping
        shippingAndHandlingFee
        stripeIntentClientSecret
        stripeIntentID
        paymentMethod {
          id
          cardholderName
          expMonth
          expYear
          isDefault
          last4
          methodId
          type
        }
        customer {
          id
        }
        subOrders {
          id
          createdAt
          digitizationFee
          discountAmount
          fulfillment
          hubspotDealId
          name
          orderId
          paidAt
          printfulOrderId
          printfulStatus
          promoRevenue
          requestedProfit
          shareASaleTracked
          shipping
          shippingAndHandlingFee
          status
          stripeIntentClientSecret
          stripeIntentID
          subtotal
          total
          type
          taxes
          printfulShipment {
            id
            status
            trackingNumber
            trackingUrl
          }
          merchOrderItems {
            id
            price
            amount
            memorabiliaId
            memorabilia {
              id
              price
              title
              memorabiliaProductType
              images {
                id
                isMainImage
                memorabiliaId
                key
                url
              }
              store {
                id
                storeDetails {
                  id
                  storeName
                }
              }
            }
            merchProductVariant {
              id
              size
              color
              colorCode
              colorCode2
              customImages {
                id
                isMainImage
                imageFileKey
                customMerchImageURL
              }
              price
              mockupImageURL
              merchProduct {
                id
                title
                productImageURL
                mainImageUrl
                store {
                  id
                  storeDetails {
                    id
                    storeName
                  }
                }
              }
            }
            merchProduct {
              id
              type
              title
              store {
                id
                storeDetails {
                  storeName
                  storeId
                }
              }
            }
          }
        }
        subtotal
        total
        taxes
      }
    }
  }
`;

export const COMPLETE_STRIPE_PAYMENT = gql`
  mutation CompleteStipePayment($input: CompleteStripePaymentInput!) {
    completeStripePayment(input: $input) {
      status
      stripeIntentID
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders($input: GetOrdersInput!) {
    getOrders(input: $input) {
      entities {
        id
        createdAt
        discountAmount
        paidAt
        subtotal
        paymentMethod {
          id
          cardholderName
          last4
          type
        }
        promoRevenue
        shipping
        shippingAndHandlingFee
        subOrders {
          id
          name
          status
          addressLine1
          addressLine2
          city
          state
          stateCode
          countryCode
          country
          zipCode
          fulfillment
          name
          printfulShipment {
            trackingNumber
            trackingUrl
            labels {
              category
              format
              page_size
              required
              url
            }
          }
          merchOrderItems {
            id
            amount
            price
            merchProductVariant {
              id
              size
              gender
              age
              type
              color
              colorCode
              colorCode2
              price
              mockupImageURL
              customImages {
                id
                isMainImage
                imageFileKey
                customMerchImageURL
              }
            }
            merchProduct {
              id
              title
              gender
              type
              productImageURL
              store {
                id
                storeDetails {
                  id
                  storeName
                }
              }
            }
            memorabilia {
              id
              price
              fulfillment
              memorabiliaProductType
              numberOfUnits
              status
              title
              images {
                id
                isMainImage
                memorabiliaId
                key
                url
              }
              store {
                id
                storeDetails {
                  id
                  storeName
                }
              }
            }
          }
        }
        total
        taxes
      }
      limit
      offset
      total
    }
  }
`;

export const GET_ANY_PRODUCT_SALES = gql`
  query GetAnyProductSales($input: GetAnyProductSalesInput!) {
    getAnyProductSales(input: $input) {
      entities {
        totalStoreEarnings
        order {
          id
          createdAt
          customer {
            id
            firstName
            lastName
          }
          discountAmount
          paidAt
          promoRevenue
          shipping
          shippingAndHandlingFee
          stripeIntentClientSecret
          stripeIntentID
          paymentMethod {
            id
            cardholderName
            expMonth
            expYear
            isDefault
            last4
            methodId
            type
          }
          subOrders {
            id
            status
            fulfillment
            addressLine1
            addressLine2
            city
            stateCode
            state
            countryCode
            country
            zipCode
            name
            printfulShipment {
              trackingNumber
              trackingUrl
              labels {
                category
                format
                page_size
                required
                url
              }
            }
            merchOrderItems {
              id
              amount
              promoDiscount
              price
              merchProductVariant {
                id
                size
                color
                type
                colorCode
                colorCode2
                customImages {
                  id
                  isMainImage
                  imageFileKey
                  customMerchImageURL
                }
                gender
                age
                price
                mockupImageURL
              }
              merchProduct {
                id
                title
                type
                gender
                productImageURL
                store {
                  id
                  storeDetails {
                    id
                    storeName
                  }
                }
              }
              memorabilia {
                id
                price
                fulfillment
                memorabiliaProductType
                numberOfUnits
                status
                title
                images {
                  id
                  isMainImage
                  memorabiliaId
                  key
                  url
                }
                store {
                  id
                  storeDetails {
                    id
                    storeName
                  }
                }
              }
            }
          }
          subtotal
          total
          taxes
        }
        totalStoreEarnings
      }
      limit
      offset
      total
    }
  }
`;

export const GET_MY_DESIGN_QUOTA = gql`
  query GetMyDesignQuota {
    getMyDesignQuota {
      nextRequestAvailableAt
      quotaPerMonth
      quotaPerMonthSpent
    }
  }
`;

export const GET_AUTO_GENERATED_LOGOS_V2 = gql`
  query GetAutoGeneratedLogosV2 {
    getAutoGeneratedLogosV2 {
      designs {
        logos {
          createdAt
          id
          merch {
            id
            slug
          }
          placement
          status
          type
          url
        }
        name
        merchTypeV2
        type
        variantIds
      }
      status
      storeId
    }
  }
`;

export const GET_STORE_MERCH_PRODUCTS = gql`
  query GetStoreMerchProducts($input: GetStoreMerchProductsInput!) {
    getStoreMerchProducts(input: $input) {
      entities {
        ...MerchProductBaseFields
      }
      total
      offset
      limit
    }
  }
  ${MerchProductBaseFields}
`;
